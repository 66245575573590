<template>
  <router-layout>
    <div class="page">
      <van-loading color="#1989fa" type="spinner" class="loading" v-show="$store.state.loading" />
      <div v-show="!$store.state.loading">
        <div class="banner">
          <van-swipe @change="onChange">
            <van-swipe-item v-for="(item, index) in swiper" :key="index">
              <img :src="item" />
            </van-swipe-item>
            <template #indicator>
              <div class="custom-indicator">{{ current + 1 }} / {{swiper.length}}</div>
            </template>
          </van-swipe>
        </div>
        <div class="sec1">
          <div class="inner">
            <h2>{{title}}</h2>
            <h3>2021.01.12 发布</h3>
            <div class="timeBox">
              <div>
                <span class="spt">价格</span>
                <span class="price">{{money}}</span>
                <span class="spt">面积</span>
                <span class="price">{{area}}㎡</span>
              </div>
              <!-- <div class="like">
                <van-checkbox v-model="like">
                  <template #icon="props">
                    <van-icon name="like" color="#f03535" v-if="props.checked" />
                    <van-icon name="like-o" v-else />
                  </template>
                </van-checkbox>
                <div>收藏</div>
              </div> -->
              <div class="pano" @click="toPano" v-show="panoShow">
                <img src="@/assets/images/pano.png"/>
              </div>
            </div>
          </div>
        </div>
        <div class="line"></div>
        <div class="sec2">
          <div class="inner">
            <h2>租赁信息</h2>
            <div>
              <span class="spt">价格</span>
              <span class="price">{{money}}</span>
              <span class="mon" v-show="hasMoney">元/月</span>
            </div>
            <ul>
              <li>
                房源类型
                <span>{{type}}</span>
              </li>
              <li>
                楼层
                <span>{{floor}}</span>
              </li>
              <li>
                朝向
                <span>{{direction}}</span>
              </li>
              <li>
                装修
                <span>{{decoration}}</span>
              </li>
            </ul>
            <div class="address" @click="toLocation">
              <div class="t">地址</div>
              <div>
                <div class="ico">
                  <img src="@/assets/images/address.png" />
                </div>
                <div class="txt">{{addRess}}</div>
              </div>
            </div>
            <div class="look_house">
              <div class="look_house_l">
                <h2>预约看房,方便带看</h2>
                <p>经纪人1对1带看服务</p>
              </div>
              <div class="lookMore" @click="toForm">我要租用</div>
            </div>
          </div>
        </div>
        <div class="line"></div>
        <div class="sec3">
          <div class="inner">
            <div class="mapt">
              <div class="tit">位置及周边</div>
              <div class="more" @click="toAround">更多</div>
            </div>
            <div class="map">
              <div class="mapbd" id="map"></div>
              <div class="mapicon">
                <div v-for="item in mapAround" @click="toMapAround(item)">
                  <span>
                    <i class="iconfont" :class="item.icon"></i>
                  </span>
                  <p>{{item.txt}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--	租赁热线		-->
        <a :href="'tel:' + phone" class="telCall">
          <van-icon name="phone" />
          <span>租赁热线</span>
        </a>
      </div>
    </div>
  </router-layout>
</template>

<script>
import authMixin from "@/mixin/mixin";
export default {
  data() {
    return {
      hasMonry:true,
      panoShow:false,
      like: false,
      current: 0,
      id: "",
      money: "",
      area: "",
      title: "",
      type: "",
      decoration: "",
      direction: "",
      floor: "/", //楼层
      phone: "",
      swiper: [],
      point: [],
      addRess: "",
      panoUrl:"",  //全景看房
      mapAround: [
        { txt: "公交", icon: "icon-gongjiao" },
        { txt: "学校", icon: "icon-xuexiao" },
        { txt: "餐饮", icon: "icon-canyin" },
        { txt: "商城", icon: "icon-gouwujianying" },
        { txt: "医院", icon: "icon-yiyuan" }
      ]
    };
  },
  mixins: [authMixin],
  created() {
    this.$store.commit("setLoading", true);
    this.id = this.$route.query.id;
    this.getData();
  },
  mounted() {},
  watch: {
    point() {
      this.$nextTick(() => {
        this.createMap();
      });
    }
  },
  activated() {},
  watch: {
    addRess() {
      this.$nextTick(() => {
        this.createMap();
      });
    }
  },
  methods: {
    onChange(index) {
      this.current = index;
    },
    toForm() {
      this.$router.push({
        path:"/Form",
        query:{
          id: this.id
        }
      });
    },
    toPano(){
      if(this.panoUrl){
        window.location.href = this.panoUrl
      }
      
    },
    createMap() {
      this.map = new AMap.Map("map", {
        resizeEnable: true,
        center: this.point,
        zoom: 13
      });
      let marker = new AMap.Marker({
        position: this.map.getCenter(),
        icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_bs.png"
      });
      marker.setMap(this.map);
      let geocoder = new AMap.Geocoder({
        city: "",
        radius: 1000 //范围，默认：500
      });
      geocoder.getAddress(this.point, (status, res) => {
        if (status === "complete" && res.regeocode) {
          let address = res.regeocode.formattedAddress;
          // this.addRess = address;
        } else {
          console.log("根据经纬度获取地址失败");
        }
      });
    },
    toMapAround(item) {
      this.$router.push({
        path: "/Near",
        query: {
          nearBy: item.txt,
          point: JSON.stringify(this.point)
        }
      });
    },
    toAround() {
      this.$router.push({
        path: "/Near",
        query: {
          nearBy: "公交",
          point: JSON.stringify(this.point)
        }
      });
    },
    toLocation() {
      this.$router.push({
        path: "/Location",
        query: {
          point: JSON.stringify(this.point),
          addRess: this.addRess
        }
      });
    },
    getData() {
      this.$request({
        method: "get",
        url: "/cms/lease/detail/" + this.id
      }).then(res => {
        this.$store.commit("setLoading", false);
        console.log(res.data);
        if (res.data) {
          if(res.data.imgs){
            this.swiper = res.data.imgs.split(",");
          }else{
            this.swiper = [require("@/assets/images/noimg.jpg")]
          }
          this.area = res.data.area;
          this.title = res.data.title;
          this.phone = res.data.phone;
          this.point = res.data.local.split(",");
          this.addRess = res.data.address;
          this.panoUrl = res.data.url
          if(res.data.url){
            this.panoShow = true
          }else{
            this.panoShow = false
          }
          if (res.data.floor) {
            this.floor = `${res.data.floor}层`;
          }

          if (res.data.money == 0) {
            this.money = "面议";
            this.hasMonry = false
          } else {
            this.money = `¥ ${res.data.money.toFixed(2)}`;
          }
          switch (res.data.type) {
            case 1:
              this.type = "商铺";
              break;
            case 2:
              this.type = "住宅";
              break;
            case 3:
              this.type = "写字楼";
              break;
            case 4:
              this.type = "厂房";
              break;
            case 5:
              this.type = "仓库";
              break;
          }
          switch (res.data.decoration) {
            case 1:
              this.decoration = "毛坯";
              break;
            case 2:
              this.decoration = "简装";
              break;
            case 3:
              this.decoration = "精装";
              break;
          }
          switch (res.data.direction) {
            case 1:
              this.direction = "朝东";
              break;
            case 2:
              this.direction = "朝南";
              break;
            case 3:
              this.direction = "朝西";
              break;
            case 4:
              this.direction = "朝北";
              break;
          }
        }
      });

      //getData end
    }
  }
};
</script>

<style scoped lang="scss" >
@import "../../../assets/iconfont/iconfont.css";
.custom-indicator {
  position: absolute;
  right: 0.3rem;
  bottom: 0.3rem;
  padding: 0.03rem 0.29rem;
  border-radius: 0.3rem;
  font-size: 0.3rem;
  color: #fff;
  background: rgba(0, 0, 0, 0.3);
}
.banner img {
  width: 100%;
}
.inner {
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}
.line {
  background: #f5f5f5;
  height: 0.25rem;
}
.sec1 {
  padding: 0.6rem 0 .8rem 0;
  .timeBox {
    display: flex;
    justify-content: space-between;
    color: #999;
    font-size: 0.34rem;
    height: 0.5rem;
    align-items: center;
    .pano{
      img{
        width: 2.6rem;
      }
    }
    .like {
      display: flex;
      align-items: center;
      .van-icon {
        font-size: 0.5rem;
        border: none;
        color: #ccc;
        margin-left: 0.05rem;
      }
      .van-checkbox__icon--checked .van-icon {
        background: transparent;
      }
    }
  }
  h2 {
    font-size: 0.45rem;
    font-weight: normal;
    margin-bottom: 0.2rem;
  }
  h3 {
    font-size: 0.38rem;
    color: #9e9e9e;
    font-weight: normal;
    margin-bottom: 0.88rem;
  }
  .price {
    color: #f03535;
    font-size: 0.42rem;
    margin-right: 0.55rem;
  }
  .spt {
    color: #333;
    font-size: 0.4rem;
    margin-right: 0.2rem;
  }
}
.sec2 {
  padding: 0.4rem 0 0.6rem 0;
  h2 {
    font-weight: normal;
    font-size: 0.45rem;
    margin-bottom: 0.5rem;
  }
  .spt {
    color: #333;
    font-size: 0.4rem;
    margin-right: 0.25rem;
  }
  .price {
    color: #f03535;
    font-size: 0.44rem;
    margin-right: 0.15rem;
  }
  .mon {
    color: #f03535;
    font-size: 0.38rem;
  }
  ul {
    height: auto;
    overflow: hidden;
    margin-top: 0.35rem;
  }
  ul li {
    float: left;
    width: 50%;
    color: #9e9e9e;
    margin-bottom: 0.2rem;
    font-size: 0.38rem;
  }
  ul li span {
    margin-left: 0.2rem;
    color: #333;
    font-size: 0.38rem;
  }
  .address {
    color: #9e9e9e;
    font-size: 0.38rem;
    .t {
      width: 1rem;
      float: left;
    }
    .txt {
      margin-right: 0.7rem;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 0.36rem !important;
    }
    .ico {
      float: right;
      width: 0.5rem;
      img {
        width: 90%;
      }
    }
    .txt {
      color: #919fbe;
      font-size: 0.38rem;
    }
  }
  .look_house {
    padding: 0.3rem 0.3rem;
    margin: 0.4rem 0 0 0;
    border-radius: 0.3rem;
    background: #e6eef8;
    height: auto;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    .look_house_l {
      float: left;
      h2 {
        font-size: 0.4rem;
        margin-bottom: 0.1rem;
      }
      p {
        font-size: 0.33rem;
        color: #999;
      }
    }
    .lookMore {
      background: #567cf3;
      color: #fff;
      height: 0.8rem;
      width: 2.3rem;
      font-size: 0.33rem;
      border-radius: 0.8rem;
      text-align: center;
      line-height: 0.8rem;
    }
  }
}
.telCall {
  text-align: center;
  color: #fff;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: linear-gradient(45deg, #78adfc, #567cf3);
  font-size: 0.43rem;
  padding: 0.25rem 0;
  i {
    margin-right: 0.08rem;
    font-size: 0.6rem;
    vertical-align: -0.17rem;
  }
}
.page {
  padding-bottom: 1.5rem;
}
.sec3 {
  padding: 0.4rem 0 0.6rem 0;
  .mapt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    .tit {
      font-size: 0.45rem;
    }
    .more {
      font-size: 0.38rem;
      color: #567cf3;
    }
  }
  .map {
    overflow: hidden;
    border-radius: 0.15rem;
    border: 1px solid #eee;
    .mapbd {
      height: 3.8rem;
      overflow: hidden;
    }
    .mapicon {
      padding: 0.3rem 0;
      display: flex;
      justify-content: space-around;
      > div:nth-child(1) span {
        background: linear-gradient(to right bottom, #a5dc57, #3cd97c);
      }
      > div:nth-child(2) span {
        background: linear-gradient(to right bottom, #83a8fd, #3396ff);
        i {
          font-size: 0.7rem;
        }
      }
      > div:nth-child(3) span {
        background: linear-gradient(to right bottom, #ff753c, #fe3b37);
        i {
          font-size: 0.49rem;
        }
      }
      > div:nth-child(4) span {
        background: linear-gradient(to right bottom, #fdb14e, #fd9a4e);
        i {
          font-size: 0.5rem;
        }
      }
      > div:nth-child(5) span {
        background: linear-gradient(to right bottom, #ffda25, #ffc91a);
      }
      span {
        display: inline-block;
        width: 0.9rem;
        height: 0.9rem;
        border-radius: 100%;
        text-align: center;
        line-height: 0.9rem;
        i {
          color: #fff;
          font-size: 0.55rem;
        }
      }
      p {
        margin-top: 0.1rem;
        color: #999;
        font-size: 0.36rem;
        text-align: center;
        color: #999;
      }
    }
  }
}
</style>
